import React, {useState} from 'react';
import {useOutlet} from 'reconnect.js';
import * as Generic from '../../Generic';
import styled from 'styled-components';
import {message} from 'antd';

function AdminSetting(props) {
  const [actions] = useOutlet('actions');

  const FormSpec = {
    schema: {
      title: '',
      type: 'object',
      required: ['orgPassword', 'newPassword'],
      properties: {
        orgPassword: {type: 'string', title: '舊密碼', default: ''},
        newPassword: {type: 'string', title: '新密碼', default: ''},
      },
    },
    uiSchema: {
      orgPassword: {
        'ui:widget': 'password',
      },
      newPassword: {
        'ui:widget': 'password',
      },
    },
  };

  return (
    <Wrapper>
      <Generic.Form
        schema={FormSpec.schema}
        uiSchema={FormSpec.uiSchema}
        onSubmit={async (formData) => {
          try {
            actions.setLoading(true);
            const values = {
              org_password: formData.orgPassword,
              new_password: formData.newPassword,
            };
            const isSuccess = await actions.AdminResetPassword(values);
            console.log('debug', isSuccess);
            if (!isSuccess) {
              throw new Error('重設密碼失敗');
            } else {
              message.success('重設密碼成功，後續請使用新密碼');
            }
          } catch (e) {
            message.error('重設密碼失敗');
          } finally {
            actions.setLoading(false);
          }
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminSetting;
